import { Modal } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCookies } from "react-cookie";
import styled from "styled-components";

import Button from "@/components/buttons/Button";
import { P, TitleWithHighlights } from "@/components/typography";

const Wrapper = styled.div`
  width: 90%;
  max-width: 688px;
  padding: 32px;
  height: auto;
  border-radius: 12px;
  z-index: 40001;
  background: #fff;

  footer {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 32px;
    width: 100%;

    .rathor_act,
    .accept_act {
      color: #fff !important;

      &:hover {
        border: 2px solid #fe517e !important;
        background: transparent;
        color: #fe517e !important;
      }
    }
  }
`;

export default function CookieBanner({
  open = false,
  setOpen,
}: {
  open?: boolean;
  setOpen?: (value: ((prevState: boolean) => boolean) | boolean) => void;
}) {
  const cookieName = process.env.NEXT_PUBLIC_COOKIE_NAME ?? "vp-cookie-consent";
  const [cookies, setCookie, removeCookie] = useCookies(); // Access removeCookie to delete cookies
  const router = useRouter();

  const d = new Date();
  const expiryDate = new Date(d.setDate(d.getDate() + 100));

  const shouldModalOpen =
    (!cookies[cookieName] || open) && router.pathname !== "/privacyverklaring";

  const handleDecline = () => {
    // Set consent cookie to false
    setCookie(cookieName, false, {
      path: "/",
      expires: expiryDate,
    });

    // Clear all cookies except the consent cookie
    Object.keys(cookies).forEach((cookie) => {
      if (cookie !== cookieName) {
        removeCookie(cookie, { path: "/" });
      }
    });

    // Close the modal
    setOpen?.(false);
  };

  const handleAccept = () => {
    // Set consent cookie to true
    setCookie(cookieName, true, {
      path: "/",
      expires: expiryDate,
    });

    // Close the modal
    setOpen?.(false);
  };

  return (
    <Modal
      open={shouldModalOpen}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Wrapper>
        <TitleWithHighlights
          text="Villa Pinedo maakt gebruik van cookies"
          textToHighlight="cookies"
          highlightColor="info"
          headerElement="h3"
        />
        <P>
          Hey jij, wat leuk dat je op onze website bent! Wij, en derden,
          gebruiken cookies om de website goed te laten werken én jouw
          voorkeuren op te slaan. Zo kunnen we jouw websitebezoek persoonlijker
          en beter maken.
        </P>
        <P>Klik op accepteren om akkoord te gaan met onze cookies.</P>
        <P>
          Meer informatie hierover vind je in onze {}
          <Link
            style={{ textDecoration: "underline" }}
            href="/privacyverklaring"
            target="_blank"
          >
            Privacy- en Cookieverklaring.
          </Link>{" "}
        </P>
        <footer>
          <Button
            variant="secondary"
            onClick={handleDecline}
            className="rathor_act"
          >
            Liever Niet
          </Button>
          <Button
            variant="primary"
            onClick={handleAccept}
            className="accept_act"
          >
            Accepteren
          </Button>
        </footer>
      </Wrapper>
    </Modal>
  );
}
