import { useEffect } from "react";
import { useCookies } from "react-cookie";

export function useConsentGiven() {
  const cookieName = process.env.NEXT_PUBLIC_COOKIE_NAME ?? "vp-cookie-consent";
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
  const isConsentGiven = cookies[cookieName] === "true";

  useEffect(() => {
    if (!isConsentGiven) {
      Object.keys(cookies).forEach((cookieName) => {
        if (
          cookieName.startsWith("_ga") ||
          cookieName.startsWith("_fbp") ||
          cookieName.startsWith("_gcl")
        ) {
          removeCookie(cookieName, { path: "/" });
        }
      });
    }
  }, [isConsentGiven, cookies, removeCookie]);

  return isConsentGiven;
}
