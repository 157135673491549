import * as React from "react";

export default function MaintenanceModal() {
  return (
    <>
      <div
        id="popup-modal"
        tabIndex={-1}
        className="h-modal fixed inset-x-0 z-50 mx-auto overflow-y-auto overflow-x-hidden p-4 md:inset-0 md:h-full"
      >
        <div className="relative flex h-full w-full items-center justify-center">
          <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
            <div className="p-6 text-center">
              <svg
                aria-hidden="true"
                className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                This site is in maintenance mode
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
